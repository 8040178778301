import { Select, Option } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import axios from "axios";
import Tabla from "./tabla";

export function SelectWithButton() {
  const [selectedOption, setSelectedOption] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>(null);
  const [ImageURL, setImageURL] = useState<string>("");
  const [TableData, setTableData] = useState<any>(null);
  const [blob, setblob] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [teamMaturity, setTeamMaturity] = useState<string>("");
  const [fecha_i, setFecha_i] = useState<string | null>(null);
  const [fecha_t, setFecha_t] = useState<string | null>(null);

  const handleSelectChange = (value: string | undefined) => {
    setSelectedOption(value);
    setError(null);
    setResponseData(null);
    setTeamMaturity("");
    setblob(null);
    setImageURL("");
    setLoading(false);
  };
  const handleButtonClick = async () => {
    if (!selectedOption){
      setError("Por favor selecciona un equipo!");
      return;
    }
    if(!fecha_i || !fecha_t){
      setError("Por favor, seleccione ambas fechas antes de continuar.");
      return;
  }
    if(fecha_i>fecha_t ){
      setError("La fecha inicial no puede ser mayor que la fecha final. Por favor, selecciona un rango de fechas válido.")
      return;
    }
    setError("");
    try {
      setLoading(true);
      var equipo = encodeURIComponent(selectedOption)
      const response = await axios.get(`https://radar.hakoe.cl/Respuestas/${equipo}`+'?fecha_in='+fecha_i+'&fecha_te='+fecha_t);
      const response_radar = await axios.post('https://radar.hakoe.cl/Respuestas/Radar',response.data);
      console.log("Respuesta recibida:", response.data);
      await recibir_imagen(response_radar.data);
      setResponseData(response.data);
      setTableData(response_radar);
      calculateTeamMaturity(response.data);
      
      
      return;
    } catch (error) {
      setResponseData(null);
      setblob(null);
      setImageURL("");
      setTeamMaturity("");
      setError("No hay respuestas para el periodo de tiempo seleccionado");
      setLoading(false);
      return;
    }
  };

  const calculateTeamMaturity = (data: any) => {
    if (!data) return;

    const { promedioGlobal, desviacionEstandar } = data;
    const maturityScore = promedioGlobal - desviacionEstandar / 2;

    if (maturityScore <= 1.7) {
      setTeamMaturity("Inicial");
    } else if (maturityScore <= 2.4) {
      setTeamMaturity("Desarrollando");
    } else if (maturityScore <= 3.2) {
      setTeamMaturity("Evolucionado");
    } else {
      setTeamMaturity("Performando");
    }
  };
  const recibir_imagen = async (data: any) => {
    if (!data) return;
    try {
      // Hacemos una solicitud POST a la URL externa con el objeto JSON
      const response = await axios.post('https://us-central1-estrategia-384920.cloudfunctions.net/function-1', data);
      var decodedData = atob(response.data);
      let arrayBuffer = new ArrayBuffer(decodedData.length);
      let uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i); }
      var blob = new Blob([uint8Array], { type: 'image/jpeg' });
      const blobUrl = URL.createObjectURL(blob);
      setblob(blob);

      let n_preguntas = data.length;
      if(n_preguntas===24){
        setImageURL("../tabla producto.png");
      }
      else if (n_preguntas===25){
        setImageURL("../tabla servicio.png");
      }
      console.log(n_preguntas);
    } catch (error) {
      // Manejamos errores en la solicitud
      console.error('Error al obtener los datos:', error);
    }
    finally{
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col items-center min-h-screen gap-4">
      <div className="max-w-screen-lg w-full">
        <div className="mb-4 items-start">
        <Select label="Selecciona Equipo" placeholder="" onChange={handleSelectChange}>
            <Option value="Activos">Activos</Option>
            <Option value="Alphatech">Alphatech (Swift/Comex)</Option>
            <Option value="Ambientes QA">Ambientes QA</Option>
            <Option value="Banca Digital Empresas">Banca Digital Empresas</Option>
            <Option value="Base de Datos">Base de Datos</Option>
            <Option value="BigData Transformación">BigData Transformación</Option>
            <Option value="Big Data Data Management">BigData Data Managment</Option>
            <Option value="BigData Analytics">BigData Analytics</Option>
            <Option value="BigData MIS">BigData MIS</Option>
            <Option value="COE Arquitectura">COE Arquitectura</Option>
            <Option value="COE Ciberseguridad">COE Ciberseguridad</Option>
            <Option value="COE Devops">COE Devops</Option>
            <Option value="COE Infraestructura">COE Infraestructura</Option>
            <Option value="COE QA">COE QA</Option>
            <Option value="QA Corporativo">QA Corporativo</Option>
            <Option value="FIshermann (Comex)">FIshermann (Comex)</Option>
            <Option value="Ingeniería de Sistemas">Ingeniería de Sistemas</Option>
            <Option value="Leasing/Scoring/ITE">Leasing/Scoring/ITE</Option>
            <Option value="Pasivos">Pasivos</Option>
            <Option value="Plataforma Comercial">Plataforma Comercial</Option>
            <Option value="Provisiones">Provisiones</Option>
            <Option value="Proyectos Digitales">Proyectos Digitales</Option>
            <Option value="Sistemas Financieros">Sistemas Financieros</Option>
            <Option value="Desarrollo BICE Inversiones">Desarrollo BICE Inversiones</Option>
            <Option value="TCR/SDG/HIP">TCR/SDG/HIP</Option>
            <Option value="Vulkano">Vulkano (RR.HH-Facturador, otros)</Option>
            <Option value="Heritage + BICE Corp + Iron Will">Heritage + BICE Corp + Iron Will</Option>

            <Option value="Morpheus">Morpheus</Option>
            <Option value="Beliv">Beliv</Option>
            <Option value="ConnectThanos">ConnectThanos</Option>
            <Option value="ID">ID</Option>
            <Option value="Lifeplan">Lifeplan</Option>
            <Option value="Propuesta de Valor y Gestion de Clientes">Propuesta de Valor y Gestion de Clientes</Option>
            <Option value="Productividad de Canales">Productividad de Canales</Option>
            <Option value="Producto Salud">Producto Salud</Option>
            <Option value="Producto Ahorro + APV">Producto Ahorro + APV</Option>
            <Option value="Producto Tradicional + Masivo">Producto Tradicional + Masivo</Option>
            <Option value="Core SVI">Core SVI</Option>
            <Option value="Core Masivos">Core Masivos</Option>
            <Option value="Operaciones">Operaciones</Option>
            <Option value="Requerimientos Estrategicos (Vulcano)">Requerimientos Estrategicos (Vulcano)</Option>
            <Option value="Core SVI (Vulcano)">Core SVI Vulcano</Option>
            <Option value="Soporte a la FFVV (Vulcano)">Soporte a la FFVV Vulcano</Option>
            <Option value="Salud Asegurada (Vulcano)">Salud Asegurada Vulcano</Option>
            <Option value="APV (Vulcano)">APV Vulcano</Option>
            <Option value="Experiencia Reembolso">Experiencia Reembolso</Option>
            <Option value="Experiencia Canales">Experiencia Canales</Option>
            <Option value="Proceso Liquidacion">Proceso Liquidacion</Option>
            <Option value="Continuidad Operativa">Continuidad Operativa</Option>
            <Option value="Venta y Renovacion">Venta y Renovacion</Option>
            <Option value="Producto y Canal Digital">Producto y Canal Digital</Option>
            <Option value="Producto y Procesos">Producto y Procesos</Option>
            <Option value="Operaciones">Operaciones</Option>

            <Option value="BICE Connect">BICE Connect</Option>
            <Option value="Astro">Astro</Option>
            <Option value="Atenea">Atenea</Option>
            <Option value="Blaze">Blaze</Option>
            <Option value="Centurion">Centurion</Option>
            <Option value="Codey">Codey</Option>
            <Option value="Enterprise">Enterprise</Option>
            <Option value="Galaxy">Galaxy</Option>
            <Option value="Gravity">Gravity</Option>
            <Option value="Hércules">Hércules</Option>
            <Option value="Hydra">Hydra</Option>
            <Option value="Morpheus">Tecnologia</Option>
            <Option value="Polaris">Polaris</Option>
            <Option value="REV9">REV9</Option>
            <Option value="Robocops">Robocops</Option>
            <Option value="Spartans">Spartans</Option>
            <Option value="Sion">Sion</Option>
            <Option value="Skyfall">Skyfall</Option>
            <Option value="Smith">Smith</Option>
            <Option value="Spartans">Spartans</Option>
            <Option value="T800">T800</Option>
            <Option value="Titan">Titan</Option>
            <Option value="Vulcano">Vulcano</Option>
            <Option value="Zeus">Zeus</Option>
            <Option value="Cloudy">Cloudy</Option>

          </Select>
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-start">
                <label  className="text-lg font-semibold text-gray-700 mb-2">Fecha inicial</label>
                <input type="date" id="fecha-inicial" onChange={ e => setFecha_i(e.target.value)} className="w-full rounded-md border border-gray-300 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"/>
            </div>

            <div className="w-full md:w-1/2 flex flex-col items-start">
                <label  className="text-lg font-semibold text-gray-700 mb-2">Fecha final</label>
                <input type="date" id="fecha-final" onChange={ e => setFecha_t(e.target.value)} className="w-full rounded-md border border-gray-300 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"/>
            </div>
        <div className="w-72 mb-4">
          <Button loading={loading} onClick={handleButtonClick} placeholder={""}>
            {loading ? "Cargando..." : "Generar informe"}
          </Button>
        </div>

        <div>
          {teamMaturity && <p>Madurez del equipo: {teamMaturity}</p>}
          {responseData && (
            <div>
              <p>Promedio Global: {responseData.promedioGlobal}</p>
              <p>Desviación Estándar Poblacional: {responseData.desviacionEstandar}</p>
              <p>tNPS: {(((responseData.NPS).toFixed(2))*100).toFixed(2)+"%"}</p>
            </div>
          )}

          <div className="grid grid-cols-5 gap-1 justify-evenly">
            <div className="col-span-3">
              {blob && <img src={URL.createObjectURL(blob)} alt="Imagen desde Blob"></img>}
              {error && <p>{error}</p>}
            </div>

            <div className="col-span-2">
              {ImageURL && <img src={ImageURL} alt="tabla"></img>}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}